import React from 'react'
import ResponsiveImages from '../../../src/common/components/ResponsiveImages/ResponsiveImages'
import { locale } from '../../../src/common/i18n'
import { getImages, ImageInputData } from '../../../src/common/utils/imagesS3'
import { SeoData } from '../../../src/common/types/Seo'

const placeholderImage = {
  Key: '',
  url: 'https://via.placeholder.com/800x200?text=Placeholder+Image',
  link: '',
  alt: '',
}
interface InSeoData {
  seoData: SeoData
}
interface Props {
  styles: { [k: string]: string }
  data?: InSeoData | ImageInputData
  tablet?: boolean
  desktop?: boolean
}

const LandingMotoEs = ({ styles, data }: Props) => {
  const [image_mobile] = getImages(
    data[`imagenes_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const [image_desktop] = getImages(
    data[`imagenes_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  const [image1_mobile] = getImages(
    data[`image1_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const [image1_desktop] = getImages(
    data[`image1_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  const [image2_mobile] = getImages(
    data[`image2_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const [image2_desktop] = getImages(
    data[`image2_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  return (
    <div className={styles.container}>
      <article className={styles.description}>
        <p className={styles.main_description}>
          En los talleres El Paso 2000 somos expertos en el mantenimiento y
          puesta a punto de motocicletas. Ofrecemos a nuestros clientes una gran
          selección de neumáticos de moto con las mejores marcas del mercado
          español para Canarias: Michelin, Continental, Pirelli, Metzeler,
          Bridgestone, Dunlop…
        </p>
        <ResponsiveImages
          imageDesktop={image_desktop}
          imageMobile={image_mobile}
        />
        <section className={styles.section}>
          <h2>¿Existen diferentes tipos de neumáticos para moto?</h2>
          <p>
            En sector de los neumáticos de motocicleta existen diferentes tipos
            de neumáticos. Estos se dividen en neumáticos de carretera,
            deportivos, mixtos o semi-asfálticos y Off-Road.
          </p>
          <p>
            A continuación, te explicamos más en detalle las características de
            cada tipo de neumático de moto:
          </p>
          <ul>
            <li>
              <strong>Neumáticos de Carretera: </strong>: Es el tipo de
              neumático más extendido. Es una clase de neumático fabricado con
              gomas duras que se adapta bien a circular a bajas temperaturas y
              que cuenta con pequeñas estrías en su superficie para evacuar el
              agua de lluvia presente en el asfalto. Lo montan la mayor parte de
              motos que vemos a diario: 125cc, scooter, maxi-scooter, custom y
              naked.
              <ResponsiveImages
                imageDesktop={image1_desktop}
                imageMobile={image1_mobile}
              />
            </li>
            <li>
              <strong>Neumático Deportivo</strong>: Es el tipo de neumático más
              extendido. Es una clase de neumático fabricado con gomas duras que
              se adapta bien a circular a bajas temperaturas y que cuenta con
              pequeñas estrías en su superficie para evacuar el agua de lluvia
              presente en el asfalto. Lo montan la mayor parte de motos que
              vemos a diario: 125cc, scooter, maxi-scooter, custom y naked.
            </li>
            <li>
              <strong>Neumático Mixto o Semi-Asfáltico</strong>: Se utilizan con
              motos de estilo Trail que están diseñadas para ser usadas fuera
              del asfalto en caminos de tierra poco exigentes. Esta categoría
              cuenta con múltiples modelos con mayor o menor adecuación a
              terrenos Off-Road. Destacan por disponer de tacos de goma marcados
              que facilitan el agarre en pista. Son fabricados con gomas duras,
              muy versátiles en uso y se adaptan bien a cualquier tipo de
              temperatura.
            </li>
            <li>
              <strong>Neumático Off Road</strong>: Destacan por usar grandes y
              profundos tacos que se agarran de forma excepcional en terrenos
              blandos. Son ideales para ser usados en pistas, caminos y
              trialeras. Estos neumáticos no son adecuados para utilizarse en
              carreteras asfaltadas y carecen, en la mayoría de los casos, de
              homologación.
              <ResponsiveImages
                imageDesktop={image2_desktop}
                imageMobile={image2_mobile}
              />
            </li>
          </ul>
        </section>
        <section className={styles.section}>
          <h2>
            ¿Cuándo se debe sustituir los neumáticos de la moto por unos nuevos?
          </h2>
          <p>
            Igual que sucede en automóviles, los neumáticos de moto cuentan con
            avisos de desgaste fácilmente apreciables que indican cuando el
            neumático ha llegado a su límite de uso. Si se desea una medición
            más precisa se puede hacer uso de un metro con el que comprobar la
            profundidad de las estrías del neumático que deben estar siempre por
            encima de los 1,6 mm de profundidad establecido por la DGT en el
            Código de Circulación.
          </p>
        </section>
      </article>
    </div>
  )
}

const LandingMoto = ({ ...props }: Props) => <LandingMotoEs {...props} />

export default LandingMoto
